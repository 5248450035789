import React, { useEffect, useState } from "react";
import ContentWrapper from "src/components/layouts/content-wrapper";
import UserStatus from "../../user-status";
import { USER_STATUS } from "src/types";
import TextInput from "src/components/atoms/text-input";
import CustomButton from "src/components/atoms/button";
import { Icon } from "src/components/atoms/icons";
import ConfirmDelete from "src/components/organisms/confirm-delete";
import AddUser from "../add-user";
import useQueryParams from "src/hooks/useQueryParams";
import {
  useDeleteCustomerUserMutation,
  useGetCustomerUserByIdMutation,
  useRejectUserMutation,
} from "src/services/api-service/user";
import { showToast } from "src/utils";
import { TOASTR_TYPES } from "src/components/atoms/toast-container/types";
import ApproveUser from "../approve-user";
import { useNavigate } from "react-router-dom";
import useFormattedDate from "src/hooks/useFormattedDate";

const UserDetailsComponent = () => {
  const router = useNavigate();
  const [storedData, setStoredData] = useState<any>({
    name: "",
    role: null,
    designation: null,
    customerid: "",
    phone: "",
    email: "",
  });
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isConfirmRejectModalOpen, setIsConfrimRejectModalOpen] =
    useState(false);
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [isApproveModalOpen, setIsApproveModalOpen] = useState(false);

  const { getQueryParam } = useQueryParams();

  const [getItemMutation] = useGetCustomerUserByIdMutation<any>();
  const [deleteItemMutation] = useDeleteCustomerUserMutation<any>();
  const [rejectUser] = useRejectUserMutation<any>();

  const [id, setId] = useState("");

  const currentUserId = getQueryParam("userId");

  const handleOpenEditModal = () => {
    setId(currentUserId);
    setAddModalOpen(true);
  };

  const handleOpenDeleteModal = (id: any) => {
    setId(currentUserId);
    setDeleteModalOpen(true);
  };

  const handleCloseApproveModal = () => {
    setId("");
    setIsApproveModalOpen(false);
  };

  const refetchData = () => {
    getDataById();
    setIsApproveModalOpen(false);
  };

  const handleConfirmDelete = () => {
    const body = {
      id,
    };
    try {
      if (id) {
        deleteItemMutation(body)
          .unwrap()
          .then((data: any) => {
            showToast(
              TOASTR_TYPES.SUCCESS,
              data?.original?.message || data?.message,
            );
            setDeleteModalOpen(false);
            // refetchData();
            setId("");
            router(-1);
          });
      }
    } catch (err) {
      console.log(err);
    }
    setDeleteModalOpen(false);
  };

  const handleConfirmReject = () => {
    if (currentUserId) {
      rejectUser({
        id: currentUserId,
      })
        .unwrap()
        .then((data: any) => {
          showToast(TOASTR_TYPES.SUCCESS, data?.message);
          refetchData();
          setIsConfrimRejectModalOpen(false);
        })
        .catch((err) => {
          showToast(TOASTR_TYPES.ERROR, err?.data?.message);
        });
    }
    setDeleteModalOpen(false);
  };

  const getDataById = async () => {
    if (currentUserId) {
      const data: any = await getItemMutation({ id: currentUserId })
        .unwrap()
        .catch((err) => {
          console.log(err);
        });
      const finalData: any = data?.data;
      setStoredData(finalData);
    }
  };

  useEffect(() => {
    getDataById();
  }, [currentUserId]);

  return (
    <ContentWrapper>
      <div className="mb-24 flex flex-wrap items-center justify-between gap-4">
        <div className="flex items-center gap-x-4">
          <h1 className=" text-24 font-bold leading-32 text-gray-800">
            User Details
          </h1>
          <UserStatus userStatus={storedData?.is_accepted} />
        </div>
        <div className="flex gap-x-3">
          {storedData?.is_accepted !== USER_STATUS.PENDING_APPROVAL && (
            <div className="gap-x-1">
              <button className="p-8" onClick={handleOpenDeleteModal}>
                <Icon.McsIcDelete />
              </button>
              <button
                className="p-8"
                onClick={handleOpenEditModal}
                disabled={storedData?.is_accepted === USER_STATUS.REJECTED}
              >
                <Icon.McsIcEdit />
              </button>
            </div>
          )}
          {storedData?.is_accepted === USER_STATUS.PENDING_APPROVAL && (
            <>
              <CustomButton
                variant="success"
                onClick={() => {
                  setIsApproveModalOpen(true);
                }}
              >
                <div className="flex items-center gap-x-2">
                  <Icon.McsIcApprove /> Approve
                </div>
              </CustomButton>
              <CustomButton
                variant="danger"
                className="w-[120px] justify-center"
                onClick={() => {
                  setIsConfrimRejectModalOpen(true);
                }}
              >
                <div className="flex items-center justify-center gap-x-2">
                  <Icon.McsIcReject />
                  Reject
                </div>
              </CustomButton>
            </>
          )}
        </div>
      </div>

      {/* Confirm reject user's request to login  */}
      <ConfirmDelete
        isOpen={isConfirmRejectModalOpen}
        toggle={() => {
          setIsConfrimRejectModalOpen(!isConfirmRejectModalOpen);
        }}
        onConfirm={() => {
          console.log("Hi");
        }}
        title="Are you sure you want to reject this User’s access?"
        message="Once rejected, the user will be informed about access rejection via email"
        icon=""
        img="/assets/images/mcs-cross.svg"
        confirmBtn={
          <CustomButton
            variant="danger"
            onClick={handleConfirmReject}
            loading={false}
            className="w-full"
          >
            <div className="flex items-center justify-center gap-x-2">
              <Icon.McsIcReject />
              Reject
            </div>
          </CustomButton>
        }
      />
      {/*  */}

      {/* Confirm Delete User */}
      <ConfirmDelete
        isOpen={isDeleteModalOpen}
        toggle={() => {
          setDeleteModalOpen(!isDeleteModalOpen);
        }}
        onConfirm={handleConfirmDelete}
        title="Are you sure you want to delete this user?"
        message="Data that has been deleted cannot be reappeared"
      />
      {/*  */}

      {/* Add user modal */}
      <AddUser
        id={id}
        setId={setId}
        isAddModalOpen={isAddModalOpen}
        setAddModalOpen={setAddModalOpen}
        refetchData={refetchData}
      />
      {/*  */}

      {/* Approve User  */}
      <ApproveUser
        handleCloseApproveModal={handleCloseApproveModal}
        updateId={id}
        isApproveModalOpen={isApproveModalOpen}
        refetchData={refetchData}
      />
      {/*  */}

      <div className="border border-b-0 border-border px-24 py-16 text-18 font-bold leading-28">
        Personal Information
      </div>
      <div className="grid border  border-border p-24">
        <div className="grid gap-x-4 lg:grid-cols-2">
          <div>
            <TextInput
              name="name"
              type="text"
              label="Name"
              placeholder="-"
              classes={{
                containerClassName: "mb-24 custom-input",
                inputClassName: "",
              }}
              value={storedData?.name}
              disabled
            />
          </div>

          <div>
            <TextInput
              name="designation"
              type="text"
              label="Role"
              placeholder="-"
              classes={{
                containerClassName: "mb-24 custom-input",
                inputClassName: "",
              }}
              value={storedData?.designation}
              disabled
            />
          </div>
          <div>
            <TextInput
              name="email"
              type="text"
              label="Email"
              placeholder="-"
              classes={{
                containerClassName: "mb-24 custom-input",
                inputClassName: "",
              }}
              value={storedData?.email}
              disabled
            />
          </div>
          <div>
            <TextInput
              name="Customer"
              type="text"
              label="Customer"
              placeholder="-"
              classes={{
                containerClassName: "mb-24 custom-input",
                inputClassName: "",
              }}
              value={storedData?.customer?.name}
              disabled
            />
          </div>
          <div>
            <TextInput
              name="Customer"
              type="text"
              label="Latest Activity"
              placeholder="-"
              classes={{
                containerClassName: "mb-24 custom-input",
                inputClassName: "",
              }}
              value={useFormattedDate(storedData?.updated_at)}
              disabled
            />
          </div>
        </div>
      </div>
    </ContentWrapper>
  );
};

export default UserDetailsComponent;
