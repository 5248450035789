import React, { useEffect, useLayoutEffect, useState } from "react";
import CustomButton from "src/components/atoms/button";
import { Icon } from "src/components/atoms/icons";
import CustomModal from "src/components/molecules/custom-modal";
import TextInput from "src/components/atoms/text-input";
import { useDownloadAllCustomersMutation } from "src/services/api-service/user";
import CustomSelect from "src/components/atoms/custom-select";
import { COUNTRIES } from "src/store/common/countries";
import { Segmented } from "antd";

const ApproveUserComponent = ({
  formik,
  resetError,
  handleCloseApproveModal,
  updateId,
  isApproveModalOpen,
  isNewCustomer,
  setisNewCustomer,
}: any) => {
  const [isDisabled, setIsDisabled] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const initialKeys = [
    "name",
    "email",
    "city",
    "country",
    "contactPersonEmail",
  ];
  const [requiredKeys, setRequiredKeys] = useState(initialKeys);

  useEffect(() => {
    setRequiredKeys(!isNewCustomer ? ["customer_id"] : initialKeys);
  }, [isNewCustomer]);

  const formValues = formik.values;
  const [storedData, setStoredData] = useState<any>({
    name: "",
    email: "",
    registrationNumber: "",
    paymentTerms: "",
    vatNumber: "",
    street: "",
    buildingNo: "",
    postalCode: "",
    city: "",
    country: "",
    contactPersonName: "",
    contactPersonPhoneNumber: "",
    contactPersonEmail: "",
  });

  const [customers, setCustomers] = useState<any>([]);

  const [getUserListMutation] = useDownloadAllCustomersMutation();

  const getData = async (params?: any) => {
    const res: any = await getUserListMutation().unwrap();

    console.log(res?.data, "dtat>>>>");

    const finalData: any = res?.data?.map((item: any) => ({
      name: item?.name,
      value: item?.id,
    }));

    setCustomers(finalData);
  };

  useEffect(() => {
    getData();
  }, []);

  useLayoutEffect(() => {
    const allKeys = Object.keys(formValues);
    const requiredValuesPresent = requiredKeys.every((key) => {
      const value = formValues[key];
      return (
        value !== undefined &&
        value !== null &&
        !(typeof value === "object" && Object.keys(value).length === 0) &&
        !(Array.isArray(value) && value.length === 0) &&
        !(typeof value === "string" && value.trim() === "")
      );
    });
    if (requiredValuesPresent) {
      if (updateId && storedData) {
        const allFieldsSame = allKeys.every(
          (key) =>
            formValues[key] === storedData[key] ||
            (formValues[key] === "" && storedData[key] === null),
        );
        setIsDisabled(allFieldsSame);
      } else {
        setIsDisabled(false);
      }
    } else {
      setIsDisabled(true);
    }
  }, [formValues, requiredKeys, storedData, updateId]);

  const toggle = () => {
    setIsModalOpen(!isModalOpen);
    document.body.style.overflow = "auto";
    handleCloseApproveModal();
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <CustomModal
        title={"Are you sure you want to approve this User’s access?"}
        subTitle={
          "To approve the user, firstly select the role of the user and secondly link this user to an existing or a new customer"
        }
        isOpen={isApproveModalOpen}
        toggle={toggle}
        maskClosable={false}
        icon={<Icon.McsIcChevronBack />}
        width={876}
        innerClass="custom-common-modal full-height"
        footer={
          <div className="w-full justify-end gap-4 lg:flex">
            <div className="order-1 lg:order-2">
              <CustomButton
                variant="success"
                className="flex w-full justify-center"
                onClick={formik.handleSubmit}
                disabled={isDisabled}
              >
                <div className="flex items-center gap-x-2">
                  <Icon.McsIcApprove /> Approve
                </div>
              </CustomButton>
            </div>
            <div className="order-2 mt-10 lg:order-1 lg:mt-0">
              <CustomButton
                variant="secondary"
                onClick={toggle}
                className="w-full"
              >
                Cancel
              </CustomButton>
            </div>
          </div>
        }
      >
        <div className="grid gap-y-5">
          <div className="">
            <h3 className="text-14 uppercase leading-32 text-gray-400">
              Step 1
            </h3>
            <h3 className="py-12 pt-10 text-18 font-normal leading-32 text-gray-800">
              Enter designation of the user
            </h3>
            <div className="grid gap-x-[16px] px-12 lg:grid-cols-3">
              <div>
                <TextInput
                  name="designation"
                  type="text"
                  label="Designation"
                  placeholder="User"
                  classes={{
                    containerClassName: "mb-12 custom-input",
                    inputClassName: "",
                  }}
                  value={formik.values.designation}
                  showError={Boolean(formik.touched.designation)}
                  onChange={(e) => {
                    resetError();
                    formik.handleChange(e);
                  }}
                  onBlur={(e) => {
                    formik.handleBlur(e);
                    resetError();
                  }}
                  errorMessage={formik.errors.designation}
                  required={false}
                />
              </div>
            </div>
          </div>
          <div>
            <h3 className="text-14 uppercase leading-32 text-gray-400">
              Step 2
            </h3>
            <div className="my-12">
              <Segmented
                defaultValue="center"
                style={{ marginBottom: 8, padding: 0 }}
                onChange={(value) => {
                  setisNewCustomer(value);
                }}
                options={[
                  { label: "Add new customer", value: 1 },
                  { label: "Link to existing customer", value: 0 },
                ]}
                value={isNewCustomer}
                className="border border-border bg-white"
              />
            </div>

            <div className="theme-custom-scroll h-[210px] overflow-y-scroll rounded-2xl bg-gray-25 p-12">
              <h3 className="pb-22 text-18 font-normal leading-32 text-gray-800">
                {Boolean(isNewCustomer)
                  ? "Customer Info"
                  : "Select an existing customer"}
              </h3>
              {/* Add Existing Customer */}
              {!Boolean(isNewCustomer) && (
                <div className="grid gap-x-[16px] lg:grid-cols-3">
                  <div>
                    <CustomSelect
                      label="Customer"
                      name="customer_id"
                      placeholder="Select Customer"
                      showSearch={true}
                      value={formik.values.customer_id || []}
                      showError={Boolean(formik.touched.customer_id)}
                      onChange={(e: any) => {
                        resetError();
                        formik.setFieldValue("customer_id", e);
                      }}
                      onBlur={(e) => {
                        formik.handleBlur(e);
                        resetError();
                      }}
                      errorMessage={formik.errors.customer_id}
                      options={customers}
                      allowClear
                      required={true}
                    />
                  </div>
                </div>
              )}
              {/*  */}

              {/* Add new customer */}
              {Boolean(isNewCustomer) && (
                <div>
                  <div className="grid gap-x-4 lg:grid-cols-3">
                    <div>
                      <TextInput
                        name="name"
                        type="text"
                        label="Customer Name"
                        placeholder="ACI Logistics DWC LLC"
                        classes={{
                          containerClassName: "mb-24 custom-input",
                          inputClassName: "",
                        }}
                        value={formik.values.name}
                        showError={Boolean(formik.touched.name)}
                        onChange={(e) => {
                          resetError();
                          formik.handleChange(e);
                        }}
                        onBlur={(e) => {
                          formik.handleBlur(e);
                          resetError();
                        }}
                        errorMessage={formik.errors.name}
                        required={true}
                      />
                    </div>
                    <div>
                      <TextInput
                        name="email"
                        type="email"
                        label="Customer Email"
                        placeholder="aleem.shaikh@aci.ae"
                        classes={{
                          containerClassName: "mb-24 custom-input",
                          inputClassName: "",
                        }}
                        value={formik.values.email}
                        showError={Boolean(formik.touched.email)}
                        onChange={(e) => {
                          resetError();
                          formik.handleChange(e);
                        }}
                        onBlur={(e) => {
                          formik.handleBlur(e);
                          resetError();
                        }}
                        errorMessage={formik.errors.email}
                        required={true}
                      />
                    </div>
                  </div>
                  <h3 className=" py-22 pt-10 text-18 font-normal leading-32 text-gray-800">
                    Customer Address
                  </h3>
                  <div className="grid gap-x-[16px] lg:grid-cols-3">
                    <div>
                      <TextInput
                        name="street"
                        type="text"
                        label="Street"
                        placeholder="ex. 4140 Parker road"
                        classes={{
                          containerClassName: "mb-24 custom-input",
                          inputClassName: "",
                        }}
                        value={formik.values.street}
                        onChange={(e) => {
                          resetError();
                          formik.handleChange(e);
                        }}
                        onBlur={(e) => {
                          formik.handleBlur(e);
                          resetError();
                        }}
                        showError={Boolean(formik.touched.street)}
                        errorMessage={formik.errors.street}
                      />
                    </div>

                    <div>
                      <TextInput
                        name="postalCode"
                        type="text"
                        label="Postal Code"
                        placeholder="ex. 22112"
                        classes={{
                          containerClassName: "mb-24 custom-input",
                          inputClassName: "",
                        }}
                        value={formik.values.postalCode}
                        onChange={(e) => {
                          resetError();
                          formik.handleChange(e);
                        }}
                        onBlur={(e) => {
                          formik.handleBlur(e);
                          resetError();
                        }}
                        showError={Boolean(formik.touched.postalCode)}
                        errorMessage={formik.errors.postalCode}
                      />
                    </div>
                    <div>
                      <TextInput
                        name="city"
                        type="text"
                        label="City"
                        placeholder="ex. Monaco"
                        classes={{
                          containerClassName: "mb-24 lg:mb-8 custom-input",
                          inputClassName: "",
                        }}
                        value={formik.values.city}
                        onChange={(e) => {
                          resetError();
                          formik.handleChange(e);
                        }}
                        onBlur={(e) => {
                          formik.handleBlur(e);
                          resetError();
                        }}
                        showError={Boolean(formik.touched.city)}
                        errorMessage={formik.errors.city}
                        required={true}
                      />
                    </div>
                    <div>
                      <CustomSelect
                        label="Country"
                        name="country"
                        placeholder="Select an option"
                        showSearch={true}
                        value={formik.values.country}
                        showError={Boolean(formik.touched.country)}
                        onChange={(e: any) => {
                          resetError();
                          formik.setFieldValue("country", e);
                          formik.handleChange(e);
                        }}
                        onBlur={(e) => {
                          formik.handleBlur(e);
                          resetError();
                        }}
                        errorMessage={formik.errors.country}
                        options={COUNTRIES}
                        allowClear
                        required={true}
                      />
                    </div>
                  </div>
                  <h3 className="py-24 pt-10 text-18 font-normal leading-32 text-gray-800">
                    Contact Person Info
                  </h3>
                  <div className="grid gap-x-[16px] lg:grid-cols-3">
                    <div>
                      <TextInput
                        name="contactPersonName"
                        type="text"
                        label="Contact Person Name"
                        placeholder="Aleem Shaikh"
                        classes={{
                          containerClassName: "mb-24 lg:mb-0 custom-input",
                          inputClassName: "",
                        }}
                        value={formik.values.contactPersonName}
                        onChange={(e) => {
                          resetError();
                          formik.handleChange(e);
                        }}
                        onBlur={(e) => {
                          formik.handleBlur(e);
                          resetError();
                        }}
                        showError={Boolean(formik.touched.contactPersonName)}
                        errorMessage={formik.errors.contactPersonName}
                      />
                    </div>
                    <div>
                      <TextInput
                        name="contactPersonPhoneNumber"
                        type="text"
                        label="Contact Person Phone Number"
                        placeholder="ex. +31 123-4567-89"
                        classes={{
                          containerClassName: "mb-24 lg:mb-0 custom-input",
                          inputClassName: "",
                        }}
                        value={formik.values.contactPersonPhoneNumber}
                        onChange={(e) => {
                          resetError();
                          formik.handleChange(e);
                        }}
                        onBlur={(e) => {
                          formik.handleBlur(e);
                          resetError();
                        }}
                        showError={Boolean(
                          formik.touched.contactPersonPhoneNumber,
                        )}
                        errorMessage={formik.errors.contactPersonPhoneNumber}
                      />
                    </div>
                    <div>
                      <TextInput
                        name="contactPersonEmail"
                        type="email"
                        label="Contact Person Email"
                        placeholder="aleem.shaikh@aci.ae"
                        classes={{
                          containerClassName: "mb-24 lg:mb-0 custom-input",
                          inputClassName: "",
                        }}
                        value={formik.values.contactPersonEmail}
                        onChange={(e) => {
                          resetError();
                          formik.handleChange(e);
                        }}
                        onBlur={(e) => {
                          formik.handleBlur(e);
                          resetError();
                        }}
                        showError={Boolean(formik.touched.contactPersonEmail)}
                        errorMessage={formik.errors.contactPersonEmail}
                        required={true}
                      />
                    </div>
                  </div>
                </div>
              )}
              {/*  */}
            </div>
          </div>
        </div>
      </CustomModal>
    </form>
  );
};

export default ApproveUserComponent;
